/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, TitleMain, Text, Button, Image, Subtitle, Title } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-241vxu --style4 --left --full" name={"uvod"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/888abe52a288417aa277a926a6a8e80c_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3 pl--0" anim={"7"} animS={"3"} style={{"maxWidth":""}}>
              
              <TitleMain className="title-box fs--86" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-custom-1);\">Půjčovna dodávek Ostrava&nbsp;</span><br>"}>
              </TitleMain>

              <Text className="text-box fs--20 w--300" style={{"maxWidth":800}} content={"Potřebujete dodávku téměř pro jakýkoliv účel? seznamte se s dodávky a vyberte si dle libosti. Všechny dodávky určené k půjčení mají dálniční známku a 200 km/den nájezdu započítaných v ceně.&nbsp;<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn4 btn-box--shape5 ff--2 fs--20 ls--002 lh--1 mt--30" href={"/#0f8ni0zgedvt"} content={"DODÁVKY"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--50 pt--50" name={"sluzby"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--4 pb--10 pt--10" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"4"}>
            
            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/25720/c3e583b2637c4f85b43c2ff76fb19370_e=187x0x673x673_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/25720/c3e583b2637c4f85b43c2ff76fb19370_e=187x0x673x673_s=350x_.png 350w, https://cdn.swbpg.com/t/25720/c3e583b2637c4f85b43c2ff76fb19370_e=187x0x673x673_s=660x_.png 660w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Několik typů\ndodávek k půjčení<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Vyberte si takovou dodávku co potřebujete.\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/25720/d4344908896f4c78920d1305949cb2ad_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/25720/d4344908896f4c78920d1305949cb2ad_s=350x_.png 350w, https://cdn.swbpg.com/t/25720/d4344908896f4c78920d1305949cb2ad_s=660x_.png 660w, https://cdn.swbpg.com/t/25720/d4344908896f4c78920d1305949cb2ad_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Optimalizováno\npro palety\n<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Některé typy dodávek určené k zapůjčeny umožňují vložit paletu do zavazadlového prostoru.\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/25720/f180045fd4cf4e34b35cc39e1c720f0d_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/25720/f180045fd4cf4e34b35cc39e1c720f0d_s=350x_.png 350w, https://cdn.swbpg.com/t/25720/f180045fd4cf4e34b35cc39e1c720f0d_s=660x_.png 660w, https://cdn.swbpg.com/t/25720/f180045fd4cf4e34b35cc39e1c720f0d_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Dodávky i pro přepravce\n<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Jste přepravce zboží a potřebujete půjčit dodávku dlouhodobě ?\n\n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"7"} style={{"maxWidth":420}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/25720/ef0d85bda0904529a7e4fd7d627ed841_s=660x_.png"} sizes={"100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/25720/ef0d85bda0904529a7e4fd7d627ed841_s=350x_.png 350w, https://cdn.swbpg.com/t/25720/ef0d85bda0904529a7e4fd7d627ed841_s=660x_.png 660w, https://cdn.swbpg.com/t/25720/ef0d85bda0904529a7e4fd7d627ed841_s=860x_.png 860w"}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":""}} content={"Rezervuj přes\ne-mail<br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Aktuálně nenabízíme žádné služby!!!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--50 pt--50" name={"o-mne"} style={{"backgroundColor":"var(--color-dominant)"}}>
          
          <ColumnWrap className="column__flex --left el--2 pb--10 pt--10 flex--center" anim={""} animS={"5"} style={{"maxWidth":1310}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":497}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":""}} content={"DODÁVKY VHODNÉ K STĚHOVÁNÍ\n"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Pokud <a href=\"https://levne-stehovani-ostrava.cz/\">stěhujete v Ostravě</a> nebo okolí, oceníte naše dodávky vhodné ke stěhování. Napište nám a informujte se o víkendové půjčení dodávky s přistavením v Ostravě."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Image alt={"Půjčovna dodávek Ostrava"} src={"https://cdn.swbpg.com/t/25720/b9b2314875f8483c9fd1aa106e035644_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/25720/b9b2314875f8483c9fd1aa106e035644_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/25720/b9b2314875f8483c9fd1aa106e035644_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/25720/b9b2314875f8483c9fd1aa106e035644_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/25720/b9b2314875f8483c9fd1aa106e035644_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/25720/b9b2314875f8483c9fd1aa106e035644_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --shape3 --left el--2 pb--10 pt--10 flex--center" anim={""} animS={"5"} style={{"maxWidth":1310,"backgroundColor":"var(--color-custom-2--95)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":497}}>
              
              <Image className="--shape3" alt={"Autopůjčovna aut a dodávek Ostrava"} src={"https://cdn.swbpg.com/t/25720/6f94ad60094e4a9cae7a15d42fcda80d_s=860x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/25720/6f94ad60094e4a9cae7a15d42fcda80d_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/25720/6f94ad60094e4a9cae7a15d42fcda80d_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/25720/6f94ad60094e4a9cae7a15d42fcda80d_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/25720/6f94ad60094e4a9cae7a15d42fcda80d_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/25720/6f94ad60094e4a9cae7a15d42fcda80d_s=2000x_.jpeg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3" anim={"2"} animS={"3"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--48" style={{"maxWidth":""}} content={"DLOUHODOBÉ PŮJČENÍ DODÁVEK\n\n"}>
              </Title>

              <Text className="text-box fs--20" style={{"maxWidth":""}} content={"Preferujeme dlouhodobé půjčení dodávky v Ostravě, proto při půjčení na dva a více měsíců nabízíme zajímavé individuální ceny a přistavení dodávky v okolí Ostravy.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dodavky"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Dodávka Mercedes Sprinter"} src={"https://cdn.swbpg.com/t/25720/85ee6120abed431e94d88243b895b60a_e=46x76x1870x1855_s=860x_.jpeg"} sizes={"100vw"} style={{"maxWidth":223}} srcSet={"https://cdn.swbpg.com/t/25720/85ee6120abed431e94d88243b895b60a_e=46x76x1870x1855_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/25720/85ee6120abed431e94d88243b895b60a_e=46x76x1870x1855_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/25720/85ee6120abed431e94d88243b895b60a_e=46x76x1870x1855_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/25720/85ee6120abed431e94d88243b895b60a_e=46x76x1870x1855_s=1400x_.jpeg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Mercedes Sprinter\n"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":299}} content={"Dodávka vhodná k přepravě stavebního materiálu a stěhování.\n"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape3 fs--16" content={"od 2420Kč/den&nbsp;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Dodávka VolksWagen Crafter"} src={"https://cdn.swbpg.com/t/25720/dd63765b4fd34f2cb2a4be0206fec4f7_s=860x_.jpeg"} sizes={"100vw"} style={{"maxWidth":223}} srcSet={"https://cdn.swbpg.com/t/25720/dd63765b4fd34f2cb2a4be0206fec4f7_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/25720/dd63765b4fd34f2cb2a4be0206fec4f7_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/25720/dd63765b4fd34f2cb2a4be0206fec4f7_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/25720/dd63765b4fd34f2cb2a4be0206fec4f7_s=1400x_.jpeg 1400w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"VolksWagen Crafter\n\n"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":299}} content={"Dodávka vhodná k přepravě stavebního materiálu a materiálů dlouhých rozměrů.\n\n"}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape3 fs--16" content={"od 2940Kč/den&nbsp;"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Dodávka Peugeot Boxter"} src={"https://cdn.swbpg.com/t/25720/f088e0adf282465d9de7e5d7e9860255_e=552x16x1294x1294_s=660x_.jpeg"} sizes={"100vw"} style={{"maxWidth":223}} srcSet={"https://cdn.swbpg.com/t/25720/f088e0adf282465d9de7e5d7e9860255_e=552x16x1294x1294_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/25720/f088e0adf282465d9de7e5d7e9860255_e=552x16x1294x1294_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/25720/f088e0adf282465d9de7e5d7e9860255_e=552x16x1294x1294_s=860x_.jpeg 860w"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Peugeot Boxter\n\n\n"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":299}} content={"Dodávka vhodná ke stěhování, možnost zapůjčení i stěhovací soupravy."}>
              </Text>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape3 fs--16" content={"od 3400Kč/den&nbsp;"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1nlfv0k pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=350x_.png);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/25720/0bc69912c2324786a8f92bbc192ceff7_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s3" anim={"7"} animS={"3"}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1--95);\">Dodávky v Ostravě pro široké použití</span><br>"}>
              </Title>

              <Text className="text-box" content={"Půjčené dodávky můžete využívat jak ke stěhování tak k transportu stavebního materiálu, či dalších věci co potřebujete.\n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":334}} content={"webové stránky informačního charakteru, vyhrazujeme si právo na chyby a odmítnout jakoukoliv poptávku.&nbsp;"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box" style={{"maxWidth":334}} content={"kam dále:<br><a href=\"https://borent.cz/\">autopůjčovna</a> <a href=\"https://bauer-pujcovna.cz/pujcovna-aut-dodavek-olomouc\">půjčovna dodávek Olomouc</a> <a href=\"http://autopujcovna-dodavek-brno.cz/\">dodávky Brno</a> <a href=\"https://cistyfiltr.cz/\">čištění DPF</a> <a href=\"https://dr-autosklo.cz/\">výměna čelního skla</a>&nbsp;<a href=\"https://alu-dvere.cz/hlinikove-vchodove-dvere/\">domovní dveře</a>&nbsp;<a href=\"https://www.dvere-simbera.cz/moderni-dvere/\">moderní dveře</a><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--right fs--26 swpf--uppercase" content={"Půjčovna dováek Ostrava"}>
              </Title>

              <Text className="text-box text-box--right" style={{"maxWidth":304}} content={"<a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}